import { KEY } from './token';
import delay from '../helpers/delay';
import { logout } from './index';

export const SESSIONTRANSFERKEY = 'SessionTokenTransfer';

// Handler that transfers sessionStorage from one tab to another based on a temporary localStorage 'SessionTokenTransfer'.
const handler = (event) => {
  if (event.key !== SESSIONTRANSFERKEY) {
    return;
  }

  console.debug('[SSO] Session storage listener: Called.');
  const token = sessionStorage.getItem(KEY);
  if (!token) {
    console.debug('[SSO] Session storage listener: No session token.');
    return;
  }

  const newToken = localStorage.getItem(SESSIONTRANSFERKEY);
  if (!newToken) {
    console.debug('[SSO] Session storage listener: No new token.');
  } else if (newToken === 'logout') {
    console.debug('[SSO] Session storage listener: Logout.');
    logout();
  } else if (token !== newToken) {
    console.debug('[SSO] Session storage listener: Changed.');
    sessionStorage.setItem(KEY, newToken);
    window.location.reload();
  } else {
    console.debug('[SSO] Session storage listener: Unchanged.');
  }
};

// listen for changes to localStorage
window.addEventListener('storage', handler, false);

export default async (token) => {
  if (!token) {
    localStorage.setItem(SESSIONTRANSFERKEY, 'logout');
  } else {
    localStorage.setItem(SESSIONTRANSFERKEY, token);
  }
  await delay(100);
  localStorage.removeItem(SESSIONTRANSFERKEY);
};
